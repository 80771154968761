var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-producer" }, [
    _vm.confirmationPopup
      ? _c("div", { staticClass: "modal__container" }, [
          _c(
            "div",
            {
              staticClass: "modalDialog",
              staticStyle: { "max-width": "600px" },
            },
            [
              _c("icon", {
                staticClass: "modalcloseButton",
                attrs: { name: "cancel" },
                nativeOn: {
                  click: function ($event) {
                    _vm.confirmationPopup = false
                  },
                },
              }),
              _c(
                "div",
                { staticClass: "modalContent modalContent--sideBySideButtons" },
                [
                  _c("div", { staticClass: "modalContent__body" }, [
                    _c("h2", { staticClass: "modal__title" }, [
                      _vm._v(
                        "Remove " +
                          _vm._s(_vm.producerEmailToDelete) +
                          " from your Co-producers? "
                      ),
                    ]),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn--grey btn--border",
                        on: {
                          click: function ($event) {
                            _vm.confirmationPopup = false
                          },
                        },
                      },
                      [_vm._v("CANCEL")]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn--danger",
                        on: {
                          click: function ($event) {
                            return _vm.updateCoProducers(
                              _vm.producerEmailToDelete
                            )
                          },
                        },
                      },
                      [_vm._v("DELETE ")]
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._m(0),
    _c("section", { staticClass: "section" }, [
      _c(
        "div",
        { staticClass: "container--fullWidth grid grid__col-small-big" },
        [
          _c(
            "div",
            { staticClass: "h-margin-30" },
            [
              _c("ValidationObserver", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var handleSubmit = ref.handleSubmit
                      return [
                        _c(
                          "form",
                          { staticClass: "form", attrs: { name: "invite" } },
                          [
                            _c(
                              "h2",
                              { staticClass: "form__title h-textCenter" },
                              [_vm._v("Add co-producer")]
                            ),
                            _c("ValidationProvider", {
                              attrs: { name: "Email", rules: { email: true } },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "formGroup" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.producerEmailToAdd,
                                                  expression:
                                                    "producerEmailToAdd",
                                                },
                                              ],
                                              staticClass:
                                                "formInput formInput--fullWidth",
                                              class: {
                                                hasError: errors.length > 0,
                                              },
                                              attrs: {
                                                name: "email",
                                                type: "text",
                                                placeholder:
                                                  "Enter producer email",
                                              },
                                              domProps: {
                                                value: _vm.producerEmailToAdd,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.producerEmailToAdd =
                                                    $event.target.value
                                                },
                                              },
                                            }),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "formInput__hint formInput__hint--error",
                                              },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "form__submit" },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "btn btn--primary btn--fullWidth",
                                                class: {
                                                  isDisabled: errors[0],
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.updateCoProducers(
                                                      _vm.producerEmailToAdd
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("ADD PRODUCER")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "container container--fullWidth wrapper--filled",
              staticStyle: { "min-height": "300px" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "flexbox flexbox--center",
                  staticStyle: { margin: "12px auto 36px" },
                },
                [
                  _c("h3", [_vm._v("My Co-producers")]),
                  _vm.thereArePendingPayments
                    ? _c(
                        "a",
                        {
                          staticClass: "btn btn--sm btn--primary",
                          staticStyle: { "margin-left": "14px" },
                          on: {
                            click: function ($event) {
                              return _vm.payCoproducer()
                            },
                          },
                        },
                        [_vm._v(" MARK ALL AS PAID")]
                      )
                    : _vm._e(),
                ]
              ),
              _c("table", [
                _vm._m(1),
                _c(
                  "tbody",
                  _vm._l(
                    _vm.coproducersWithPendingPayments,
                    function (producer) {
                      return _c("tr", { staticClass: "order-details" }, [
                        _c("td", [_vm._v(_vm._s(producer.coproducer))]),
                        _c("td", [_vm._v("$" + _vm._s(producer.amount))]),
                        _c("td", { staticClass: "right" }, [
                          producer.amount > 0
                            ? _c(
                                "a",
                                {
                                  staticClass:
                                    "btn btn--sm btn--danger btn--border hideOnMobile",
                                  on: {
                                    click: function ($event) {
                                      return _vm.payCoproducer(
                                        producer.coproducer
                                      )
                                    },
                                  },
                                },
                                [_vm._v("CLEAR AMOUNT")]
                              )
                            : _vm._e(),
                          producer.amount > 0
                            ? _c(
                                "a",
                                {
                                  staticClass:
                                    "btn btn--xs btn--danger btn--border hideOnDesktop",
                                  on: {
                                    click: function ($event) {
                                      return _vm.payCoproducer(
                                        producer.coproducer
                                      )
                                    },
                                  },
                                },
                                [_vm._v("CLEAR")]
                              )
                            : _vm._e(),
                        ]),
                        _c("td", [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.openConfirmationPopup(
                                    producer.coproducer
                                  )
                                },
                              },
                            },
                            [_c("icon", { attrs: { name: "cancel" } })],
                            1
                          ),
                        ]),
                      ])
                    }
                  ),
                  0
                ),
              ]),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "header header--borderBottom" }, [
      _c("div", { staticClass: "container headerContainer" }, [
        _c("div", { staticClass: "headerContainer__heading" }, [
          _c("h3", [_vm._v("Account")]),
          _c("h1", [_vm._v("Co-producers")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "order-details" }, [
        _c("th", [_vm._v("EMAIL")]),
        _c("th", [_vm._v("AMOUNT")]),
        _c("th"),
        _c("th"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }